<template>
  <div class="container-fluid">
    <div class="row row-cols-1">
      <div class="col-12">
        <div class="row row-cols-1 mt-5">
          <div class="col">
            <div class="card border border-0">
              <div class="card-body">
                <StatForm @query="initMonthlyTable"></StatForm>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="alert alert-warning" role="alert">
            <ol class="m-0">
              <li class="mb-1">所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況</li>
              <li class="mb-1">本站將盡力提供正確之資訊，惟本網站對於所載資料之完整性、即時性和正確性不做任何擔保</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mb-4 bg-light rounded" v-if="showCreateSection">
      <h2 class="text-center p-2">{{ stat_main_title }}</h2>
      <div class="container-fluid mt-3 bg-light rounded">
        <div class="table-wrap table-responsive">
          <table class="table table-borderless table-striped">
            <thead>
              <tr class="p-0">
                <td class="w100 p-0 align-middle" scope="col" rowspan="2">
                  <p class="btn btn-primary h-1 px-2 fs-5">同區域其他班次</p>
                  <!-- <br />
                  <p><small>比較票種: 台灣虎航 tigerlight</small></p> -->
                </td>
                <td class="text-center w100 p-0 py-2 align-middle" scope="col" :colspan="colspan">
                  <b class="text-muted"
                    ><h3>
                      <p class="text-primary">{{ oneway_main_title }}</p>
                    </h3>
                    <small>依旅行日期，全月平均(單程未稅)</small></b
                  >
                </td>
              </tr>
              <tr>
                <td class="text-center w100 p-0 py-2 align-middle" scope="col" v-for="(item, key) in statMonthRange" :key="key">
                  <b class="text-muted">{{ item }}</b>
                </td>
                <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                  <b class="text-muted">備註</b>
                </td>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr class="border-bottom bg-white" v-for="(item, key) in rows" :key="key">
                <td class="row text-left">
                  <div class="d-flex align-items-center">
                    <span :class="'bg-' + item._airline + ' mx-2'"></span>
                    <div class="flex-column">
                      <h4>
                        <small class="fs-6">{{ item._airlineName }}</small> {{ item._flightNo }}<br />
                      </h4>
                      <small class="fs-6">票種: {{ item._fareType }} <font-awesome-icon icon="fa-solid fa-suitcase" v-if="item._airline == 'SL' || item._airline == 'OD' || item._airline == 'TW' || item._airline == 'BX' || item._airline == 'LJ' || item._airline == '7C' || item._flightNo.substr(0, 3) == 'IT6' || item._flightNo == 'IT8636' || item._flightNo == 'IT8637' || item._fareType == 'tigersmart' || item._fareType == 'Standard'" /></small>
                    </div>
                  </div>
                </td>
                <template v-for="(iitem, key) in item._res">
                  <td class="text-center align-middle fs-5" :key="key" v-if="iitem.avg_thisMonByQD == -1 || iitem.avg_thisMonByQD === null">-</td>
                  <td class="text-center align-middle fs-5" :key="key" v-if="iitem.avg_thisMonByQD > 0">
                    <span class="text-dark" v-if="iitem.avg_thisMonByQD > 8000">TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                    <span class="text-dark" v-else-if="iitem.avg_thisMonByQD > 6000">TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                    <span class="text-dark" v-else-if="iitem.avg_thisMonByQD < 3000">TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                    <span v-else>TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                  </td>
                </template>

                <!-- <td class="text-center align-middle fs-5" v-if="item._res[6].avg_thisMonByQD == -1">-</td>
              <td class="text-center align-middle fs-5" v-if="item._res[6].avg_thisMonByQD > 0">TWD {{ item._res[6].avg_thisMonByQD.toLocaleString() }}</td> -->
                <td class="text-center align-middle fs-6">
                  <span class="text-secondary" v-if="(item._flightNo == 'IT282' || item._flightNo == 'IT283') && statMonth == '2023-11'"><B>增班，已於 11/14 開放訂位</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'IT256' || item._flightNo == 'IT257') && statMonth == '2023-11'"><B>預計 12/10 開航</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'IT288' || item._flightNo == 'IT289') && statMonth == '2023-12'"><B>預計 2024/1/16 復飛</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'OD890' || item._flightNo == 'OD891') && season == 'W23'"><B>預計 2024/2/7 開航</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'MM6629' || item._flightNo == 'MM6630') && statMonth == '2023-11'"><B>增班，12-2月指定日期運行</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'IT2200' || item._flightNo == 'IT2201') && statMonth == '2023-12'"><B>增班，3月限定運行</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'TW9663' || item._flightNo == 'TW9664') && statMonth == '2023-12'"><B>增班，1-3月指定日期運行</B></span>
                  <span class="text-secondary" v-else><B>-</B></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-start align-middle" rowspan="2" :colspan="colspan2">
                  <small>
                    {{ stat_note1_text }}<br />
                    {{ stat_note2_text }}<br />
                    <!-- *十月份稅金(成人)參考: 台灣虎航 TWD 500<br /> -->
                    <small>*如票種旁有標示 <font-awesome-icon icon="fa-solid fa-suitcase" />，意即該價格有包含免費託運行李</small><br />
                    *以未稅原價計算，並 <span class="text-danger fs-4">排除掉</span> 活動優惠價、促銷價及旅展優惠價</small
                  >
                </td>
                <td class="text-end align-middle" :colspan="colspan"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="table-wrap table-responsive">
          <table class="table table-borderless table-striped">
            <thead>
              <tr class="p-0">
                <td class="w100 p-0 align-middle" scope="col" rowspan="2">
                  <p class="btn btn-primary h-1 px-2 fs-5">同區域其他班次</p>
                  <!-- <br />
                  <p><small>比較票種: 台灣虎航 tigerlight</small></p> -->
                </td>
                <td class="text-center w100 p-0 py-2 align-middle" scope="col" :colspan="colspan">
                  <b class="text-muted"
                    ><h3>
                      <p class="text-primary">{{ return_main_title }}</p>
                    </h3>
                    <small>依旅行日期，全月平均(單程未稅)</small></b
                  >
                </td>
              </tr>
              <tr>
                <td class="text-center w100 p-0 py-2 align-middle" scope="col" v-for="(item, key) in statMonthRange" :key="key">
                  <b class="text-muted">{{ item }}</b>
                </td>
                <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                  <b class="text-muted">備註</b>
                </td>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr class="border-bottom bg-white" v-for="(item, key) in rows2" :key="key">
                <td class="row text-left">
                  <div class="d-flex align-items-center">
                    <span :class="'bg-' + item._airline + ' mx-2'"></span>
                    <div class="flex-column">
                      <h4>
                        <small class="fs-6">{{ item._airlineName }}</small> {{ item._flightNo }}<br />
                      </h4>
                      <small class="fs-6">票種: {{ item._fareType }} <font-awesome-icon icon="fa-solid fa-suitcase" v-if="item._airline == 'SL' || item._airline == 'OD' || item._airline == 'TW' || item._airline == 'BX' || item._airline == 'LJ' || item._airline == '7C' || item._flightNo.substr(0, 3) == 'IT6' || item._flightNo == 'IT8636' || item._flightNo == 'IT8637' || item._fareType == 'tigersmart' || item._fareType == 'Standard'" /></small>
                    </div>
                  </div>
                </td>

                <template v-for="(iitem, key) in item._res">
                  <td class="text-center align-middle fs-5" :key="key" v-if="iitem.avg_thisMonByQD == -1 || iitem.avg_thisMonByQD === null">-</td>
                  <td class="text-center align-middle fs-5" :key="key" v-if="iitem.avg_thisMonByQD > 0">
                    <span class="text-dark" v-if="iitem.avg_thisMonByQD > 8000">TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                    <span class="text-dark" v-else-if="iitem.avg_thisMonByQD > 6000">TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                    <span class="text-dark" v-else-if="iitem.avg_thisMonByQD < 3000">TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                    <span v-else>TWD {{ (iitem.avg_thisMonByQD | round).toLocaleString() }}</span>
                  </td>
                </template>

                <!-- <td class="text-center align-middle fs-5" v-if="item._res[6].avg_thisMonByQD == -1">-</td>
              <td class="text-center align-middle fs-5" v-if="item._res[6].avg_thisMonByQD > 0">TWD {{ item._res[6].avg_thisMonByQD.toLocaleString() }}</td> -->
                <td class="text-center align-middle fs-6">
                  <span class="text-secondary" v-if="(item._flightNo == 'IT282' || item._flightNo == 'IT283') && statMonth == '2023-11'"><B>增班，已於 11/14 開放訂位</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'IT256' || item._flightNo == 'IT257') && statMonth == '2023-11'"><B>預計 12/10 開航</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'IT288' || item._flightNo == 'IT289') && statMonth == '2023-12'"><B>預計 2024/1/16 復飛</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'OD890' || item._flightNo == 'OD891') && season == 'W23'"><B>預計 2024/2/7 開航</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'MM6629' || item._flightNo == 'MM6630') && statMonth == '2023-11'"><B>增班，12-2月指定日期運行</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'IT2200' || item._flightNo == 'IT2201') && statMonth == '2023-12'"><B>增班，3月限定運行</B></span>
                  <span class="text-secondary" v-else-if="(item._flightNo == 'TW9663' || item._flightNo == 'TW9664') && statMonth == '2023-12'"><B>增班，1-3月指定日期運行</B></span>
                  <span class="text-secondary" v-else><B>-</B></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-start align-middle" rowspan="2" :colspan="colspan2">
                  <small>
                    {{ stat_note1_text }}<br />
                    {{ stat_note2_text }}<br />
                    <!-- *十月份稅金(成人)參考: 台灣虎航 TWD 858<br /> -->
                    <small>*如票種旁有標示 <font-awesome-icon icon="fa-solid fa-suitcase" />，意即該價格有包含免費託運行李</small><br />
                    *以未稅原價計算，並 <span class="text-danger fs-4">排除掉</span> 活動優惠價、促銷價及旅展優惠價</small
                  >
                </td>
                <td class="text-end align-middle" :colspan="colspan"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
  /* @import url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.3.0/css/fontawesome.min.css"); */
</style>
<style lang="css" src="@/css/a.css" scoped></style>
<script>
  import StatForm from "./StatForm.vue"
  import axios from "axios"
  //   import moment from "moment"
  import round from "vue-round-filter"

  export default {
    name: "VueStatView",
    components: {
      StatForm,
      // Loading,
    },
    data: function () {
      return {
        showCreateSection: false,
        // q: {
        //   dept: "",
        //   arri: "",
        //   query_d: "",
        // },
        rows: [],
        rows2: [],
        season: "",
        statMonth: "",
        colspan: 7,
        colspan2: 3,
        statMonthRange: "",
        stat_main_title: "",
        stat_note1_text: "",
        stat_note2_text: "",
        oneway_main_title: "",
        return_main_title: "",
      }
    },
    filters: {
      round,
    },
    methods: {
      initMonthlyTable(val) {
        this.showCreateSection = true
        const dept = val.countryName1.split("|")[1]
        const arri = val.cityName1.split("|")[1]
        const query_d = val.selectedItems1

        const me = this
        const config = {
          headers: { Authorization: `Bearer WHEQs8dl34QW5cYiYORU` },
        }

        axios.get(`${this.$apiUrl}monthly/${dept}/${arri}/${query_d}`, config).then((response) => {
          me.rows = response.data["stat_x"]
          me.statMonth = response.data["stat_info"].statMonth
          me.season = response.data["stat_info"].season
          if (me.season == "W23") {
            if (me.statMonth == "2023-11") {
              me.colspan = 7
              me.colspan2 = 5
            } else if (me.statMonth == "2023-12") {
              me.colspan = 6
              me.colspan2 = 4
            } else if (me.statMonth == "2024-01") {
              me.colspan = 5
              me.colspan2 = 3
            } else {
              me.colspan = 4
              me.colspan2 = 2
            }
          }

          if (me.season == "S24") {
            if (me.statMonth == "2024-03") {
              me.colspan = 9
            } else if (me.statMonth == "2024-04") {
              me.colspan = 8
            } else if (me.statMonth == "2024-05") {
              me.colspan = 7
            } else if (me.statMonth == "2024-06") {
              me.colspan = 6
            } else if (me.statMonth == "2024-07") {
              me.colspan = 5
            } else if (me.statMonth == "2024-08") {
              me.colspan = 4
            } else {
              me.colspan = 10
            }
          }

          if (me.season == "W24") {
            // if (me.statMonth == "2024-07") {
            // me.colspan = 9
            // } else {
            me.colspan = 7
            // }
          }
          me.stat_main_title = response.data["stat_info"].main_title
          me.statMonthRange = response.data["stat_info"].statMonthRange
          me.oneway_main_title = response.data["stat_info"].sub_title
          me.stat_note1_text = response.data["stat_info"].note1_text
          me.stat_note2_text = response.data["stat_info"].note2_text
        })

        axios.get(`${this.$apiUrl}monthly/${arri}/${dept}/${query_d}`, config).then((response) => {
          me.rows2 = response.data["stat_x"]
          me.statMonthRange = response.data["stat_info"].statMonthRange
          me.return_main_title = response.data["stat_info"].sub_title
          me.stat_note1_text = response.data["stat_info"].note1_text
          me.stat_note2_text = response.data["stat_info"].note2_text
        })
      },
    },
  }
</script>
