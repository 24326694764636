<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-5">
        <main class="mt-3 w-75 m-auto">
          <div class="card">
            <div class="card-body">
              <form class="row g-3" @submit.prevent="getFormValues">
                <div class="col-lg-6 col-xs-12">
                  <label for="formGroupExampleInput" class="form-label fs-3">將自動前往 {{ getAirlineName(refContent.airline) }} 官方網站</label>
                  <input type="text" class="form-control" id="formGroupExampleInput" placeholder="載入中請稍後..." v-model="bookingUrl" readonly />
                </div>
                <div class="col-12">
                  <span id="passwordHelpInline" class="form-text"> 因時效性關係，實際價格均以各航空公司官方訂位系統上所查詢為準 </span>
                </div>
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary btn-lg">{{ countDown }} 秒後將自動前往</button>
                  <p class="mt-3">或者可點擊 <a :href="bookingUrl">此連結</a> 前往</p>
                </div>
              </form>
            </div>

            <div class="card-body" v-if="rows.res0 && rows.res0.length > 0">
              <p>
                也許會感興趣的，<span class="fs-3">{{ rows.title }}</span>
              </p>
              <hr />
              <section>
                <div class="row row-cols-1 row-cols-md-3 g-4" v-if="rows.res0.length > 0">
                  <div class="col" v-for="(item, key) in rows.res0" :key="key">
                    <div class="card text-bg-light">
                      <!-- <div class="card-header">{{ item.departureDate }}</div> -->
                      <div class="card-body">
                        <h5 class="card-title">
                          出發日 {{ item.departureDate }} <span class="fs-3">{{ item.flightNo }}</span>
                        </h5>
                        <p class="card-title">
                          <!-- 出發日 {{ item.departureDate }} -->
                          <!-- <span class="fs-4">{{ item.departureStation }} </span><font-awesome-icon icon="plane-departure" /> <span class="fs-4">{{ item.arrivalStation }}</span>  -->
                          近期價格 {{ item.currency }} <span class="fs-4">{{ item.fareAmount.toLocaleString() }} </span>(<span v-if="item.fare_trend_diff1 < 0"
                            ><B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e"></i></B>{{ item.fare_trend_diff1.toLocaleString() }})</span
                          >
                          <span v-else-if="item.fare_trend_diff1 == 0">{{ item.fare_trend_diff1.toLocaleString() }})</span>
                          <span v-else
                            ><B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000"></i></B>{{ item.fare_trend_diff1.toLocaleString() }})</span
                          >
                        </p>
                        <div class="text-end">
                          <a :href="showlink(item.airline, item.departureDate, item.departureStation, item.arrivalStation, null, 'justbengkueime', '100', 'text')" class="card-link">前往</a>
                        </div>
                      </div>
                      <div class="card-footer text-body-secondary text-end fs-6">資料時間 {{ item.fetchCreatedAt }}</div>
                    </div>
                  </div>
                </div>
              </section>
              <p class="mt-3 fs-6">*僅供參考，價格皆為單程未稅，所提供資料均是建立在大人人數為１的情況，由於有時效性問題，建議要留意一下資料取得時間</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios"
  import moment from "moment"
  import iataCode from "@/mixins/iataCode.js"
  export default {
    metaInfo() {
      return {
        meta: [
          { property: "og:title", content: `Just.崩潰 帶你前往 ${this.refString}` },
          // { property: "og:type", content: "website" },
          { property: "og:url", content: this.justbkUrl },
          // { property: "og:description", content: "「每一個票價在當下都代表一個意義」，本站提供便捷的查詢方式，搭配視覺化圖表呈現，可以快速瞭解過去機票價格情形，目前以有經營台灣往返東北亞（日韓），澳門、東南亞（泰越）航線的低成本航空(LCC) 業者為主（台灣虎航、樂桃航空、酷航、釜山航空、濟州航空、德威航空、真航空、泰國獅子航空、泰國越捷航空、馬來西亞峇迪航空、泰國亞洲航空及越捷航空）。" },
          // { property: "og:image", content: "https://i.imgur.com/XzaW2ig.jpg" },
        ],
      }
    },
    mixins: [iataCode],
    data() {
      return {
        countDown: 11,
        refString: null,
        justbkUrl: null,
        bookingUrl: null,
        refContent: {},
        webUrl: this.$webUrl,
        // channel: [],
        // affiliateCode: "",
        rows: [],
        trackCode: "goLink",
      }
    },
    async mounted() {
      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        const airline = ref.split("-")[0]

        let passenger_numbers = 100
        // let track_code = "goLink"
        let logger = {
          action: "GO_LINK",
          value0: "GUSET",
          value1: ref,
          value2: 0,
          value3: passenger_numbers,
          value4: this.trackCode,
        }

        // if (this.$route.query.type || this.$route.query.t) {
        //   this.trackCode = "notifyLink"
        // }
        if (this.$route.query.type || this.$route.query.t) {
          if (this.$route.query.type == 1 || this.$route.query.t == 1) {
            this.trackCode = "notifyLink"
          }
          if (this.$route.query.type == 2 || this.$route.query.t == 2) {
            this.trackCode = "emailLink"
          }
          if (this.$route.query.type == 3 || this.$route.query.t == 3) {
            this.trackCode = "lowfareLink"
          }
          logger["value4"] = this.trackCode
          axios.post(`${this.$apiUrl2}logger`, logger)
          //   // window.location.href = this.bookingUrl
          //   console.log(trackCode)
        }

        if (airline === "MM_PROMO") {
          logger["value3"] = "000"
          // console.log(logger)
          const res = await axios.get(`${this.$apiUrl2}get_code?airline=${airline}`)
          const redirectUrl = res.data.data[airline]

          // promoCode = ""
          axios.post(`${this.$apiUrl2}logger`, logger)
          this.bookingUrl = redirectUrl
          window.location.href = redirectUrl
        } else {
          const departureStation = ref.split("-")[1].substring(0, 3)
          const arrivalStation = ref.split("-")[1].substring(3, 6)
          const departureDate = ref.split("-")[2] ? ref.split("-")[2] : null
          const arrivalDate = ref.split("-")[3] ? ref.split("-")[3] : null
          if (departureDate !== null) {
            if (arrivalDate !== null) {
              this.refString = `${airline}-${departureStation}${arrivalStation}-${departureDate}-${arrivalDate}`
            } else {
              this.refString = `${airline}-${departureStation}${arrivalStation}-${departureDate}`
            }
          } else {
            this.refString = `${airline}-${departureStation}${arrivalStation}`
          }
          this.refContent["airline"] = airline
          this.refContent["departureStation"] = departureStation
          this.refContent["arrivalStation"] = arrivalStation
          this.refContent["departureDate"] = departureDate
          this.justbkUrl = `${this.webUrl}go?ref=${this.refString}`

          let affiliateChannel = "justbengkueime",
            promoCode = ""

          if (airline === "IT" && (moment(departureDate).isBefore("2024-10-31") || moment(arrivalDate).isBefore("2024-10-31") || departureDate === null || arrivalDate === null)) {
            promoCode = ""
          }

          if (this.$route.query.p) {
            passenger_numbers = this.$route.query.p
            logger["value3"] = passenger_numbers
          }

          this.getRelationshipFare(airline, departureDate, departureStation, arrivalStation, arrivalDate)
          this.bookingUrl = this.showlink(airline, departureDate, departureStation, arrivalStation, arrivalDate, affiliateChannel, passenger_numbers, promoCode, this.trackCode, "text")

          if (this.$route.query.skip || this.$route.query.s) {
            logger["value2"] = 1
            axios.post(`${this.$apiUrl2}logger`, logger)
            window.location.href = this.bookingUrl
          }

          // console.log(logger)
          axios.post(`${this.$apiUrl2}logger`, logger)
          this.countDownTimer()
        }
      }
    },
    methods: {
      countDownTimer() {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
        if (this.countDown === 0) {
          window.location.href = this.bookingUrl
        }
      },
      async getFormValues(e) {
        e.preventDefault()
        // window.location.href = this.bookingUrl
      },
      async getRelationshipFare(airline, departureDate, departureStation, arrivalStation, arrivalDate) {
        const me = this
        axios({
          method: "POST",
          url: `${me.$apiUrl}getRelationshipFare`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            queryform: {
              airline: airline,
              departureDate: departureDate,
              departureStation: departureStation,
              arrivalStation: arrivalStation,
              arrivalDate: arrivalDate,
            },
          },
        }).then(function (response) {
          // console.log(response.data)
          const res = response.data
          // console.log("res", res.notify_manage_data)
          me.rows = res
        })
      },
      showlink(airline, departureDate, departureStation, arrivalStation, arrivalDate, affiliateChannel = "justbengkueime", passenger_numbers = "100", promoCode, trackCode, show_type = "text") {
        let booking_url = ""
        const adult = passenger_numbers.toString().substring(0, 1)
        const children = passenger_numbers.toString().substring(1, 2)
        const infant = passenger_numbers.toString().substring(2, 3)

        switch (airline) {
          case "LJ": {
            if (departureDate !== null) {
              const newDepartureDate = moment(departureDate).format("YYYYMMDD")
              if (arrivalDate !== null) {
                const newArrivalDate = moment(arrivalDate, "YYYYMMDD")
                const days_diff = newArrivalDate.diff(moment(departureDate, "YYYYMMDD"), "days")

                booking_url = `https://www.jinair.com/booking/itinerary/index?arrcity1=${arrivalStation}&tripType=RT&depcity1=${departureStation}&tripday1=${days_diff}&depdate1=${newDepartureDate}&adultPaxCount=${adult}&childPaxCount=${children}&infantPaxCount=${infant}`
              } else {
                booking_url = `https://www.jinair.com/booking/itinerary/index?arrcity1=${arrivalStation}&tripType=OW&depcity1=${departureStation}&tripday1=N&depdate1=${newDepartureDate}&adultPaxCount=${adult}&childPaxCount=${children}&infantPaxCount=${infant}`
              }
            } else {
              booking_url = `https://www.jinair.com/booking/itinerary/index?arrcity1=${arrivalStation}&tripType=OW&depcity1=${departureStation}&tripday1=N&depdate1=&adultPaxCount=${adult}&childPaxCount=${children}&infantPaxCount=${infant}`
            }
            break
          }
          case "TW": {
            // https://www.twayair.com/app/booking/searchItinerary?tripType=OW&depAirportCode=KHH&arrAirportCode=ICN&flightDate=2024-07-01
            // https://www.twayair.com/app/booking/searchItinerary?tripType=OW&depAirportCode=KHH&arrAirportCode=ICN&flightDate=2024-07-01
            const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")

            if (arrivalDate !== null) {
              // const newArrivalDate = moment(arrivalDate, "YYYYMMDD")
              // const days_diff = newArrivalDate.diff(moment(departureDate, "YYYYMMDD"), "days")

              booking_url = `https://www.twayair.com/app/booking/searchItinerary?tripType=RT&depAirportCode=${departureStation}&arrAirportCode=${arrivalStation}&flightDate=${newDepartureDate}`
            } else {
              booking_url = `https://www.twayair.com/app/booking/searchItinerary?tripType=OW&depAirportCode=${departureStation}&arrAirportCode=${arrivalStation}&flightDate=${newDepartureDate}`
            }

            // https://www.jinair.com/booking/itinerary/index?tripType=RT&depcity1=TPE&arrcity1=TAE&depdate1=20240715&tripday1=5
            break
          }
          // case "SL": {
          //   // https://search.lionairthai.com/search.aspx?aid=207&depCity=TPE&arrCity=NRT&Jtype=2&depDate=07%2F06%2F2024&arrDate=08%2F06%2F2024&adult1=6&child1=2&infant1=0&promotioncode=&df=UK&afid=0&b2b=0&St=fa&DFlight=false&roomcount=1&sid=MQAuADEANwA1AC4AMQA0ADUALgA0ADkALAAgADEANwAyAC4ANwAxAC4AMgAwADQALgAxADYAMQA%3D&culture=zh-CHS
          //   const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")

          //   if (arrivalDate !== null) {
          //     // const newArrivalDate = moment(arrivalDate, "YYYYMMDD")
          //     // const days_diff = newArrivalDate.diff(moment(departureDate, "YYYYMMDD"), "days")

          //     booking_url = `https://www.twayair.com/app/booking/searchItinerary?tripType=RT&depAirportCode=${departureStation}&arrAirportCode=${arrivalStation}&flightDate=${newDepartureDate}`
          //   } else {
          //     booking_url = `https://www.twayair.com/app/booking/searchItinerary?tripType=OW&depAirportCode=${departureStation}&arrAirportCode=${arrivalStation}&flightDate=${newDepartureDate}`
          //   }

          //   // https://www.jinair.com/booking/itinerary/index?tripType=RT&depcity1=TPE&arrcity1=TAE&depdate1=20240715&tripday1=5
          //   break
          // }
          case "7C": {
            const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")
            let availSearchData = { agentId: "deep_everymundo", cultureCode: "zh-TW", includeTaxes: true, paxTypeCount: { adultCount: adult, childCount: children, infantCount: infant }, routeType: "I", tripRoutes: [{ departureDate: newDepartureDate, destination: arrivalStation, fareBasisCode: "", flightNumber: [""], origin: departureStation }], tripType: "OW", promotionCode: "" }
            if (arrivalDate !== null) {
              const newArrivalDate = moment(arrivalDate).format("YYYY-MM-DD")
              availSearchData = {
                agentId: "deep_everymundo",
                cultureCode: "zh-TW",
                includeTaxes: true,
                paxTypeCount: { adultCount: adult, childCount: children, infantCount: infant },
                routeType: "I",
                tripRoutes: [
                  { departureDate: newDepartureDate, destination: arrivalStation, fareBasisCode: "", flightNumber: [""], origin: departureStation },
                  { departureDate: newArrivalDate, destination: departureStation, fareBasisCode: "", flightNumber: [""], origin: arrivalStation },
                ],
                tripType: "RT",
                promotionCode: "",
              }
            }

            // {"agentId":"deep_everymundo","cultureCode":"zh-TW","includeTaxes":true,"paxTypeCount":{"adultCount":"1","childCount":"0","infantCount":"0"},"routeType":"I","tripRoutes":[{"departureDate":"2024-06-04","destination":"ICN","fareBasisCode":"","flightNumber":[""],"origin":"TPE"},{"departureDate":"2024-06-04","destination":"TPE","fareBasisCode":"","flightNumber":[""],"origin":"ICN"}],"tripType":"RT","promotionCode":""}

            const query_str = encodeURIComponent(JSON.stringify(availSearchData))
            booking_url = `https://www.jejuair.net/zh-tw/ibe/booking/AvailSearch.do?availSearchData=${query_str}`
            break
          }
          case "IT": {
            if (departureDate !== null) {
              const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")
              if (arrivalDate !== null) {
                const newArrivalDate = moment(arrivalDate).format("YYYY-MM-DD")
                //   booking_url = `https://booking.tigerairtw.com/?type=oneWay&outbound=${departureStation}-${arrivalStation}&departureDate=${newDepartureDate}&adult=1&children=0&infant=0&languageCode=zh-tw`
                booking_url = `https://booking.tigerairtw.com/?type=roundTrip&outbound=${departureStation}-${arrivalStation}&inbound=${arrivalStation}-${departureStation}&departureDate=${newDepartureDate}&returnDate=${newArrivalDate}&adult=${adult}&children=${children}&infant=${infant}&languageCode=zh-tw&affiliateChannel=${affiliateChannel}&affiliateCode=${trackCode}-${departureStation}${arrivalStation}-${departureDate}-${arrivalDate}&promotionCode=${promoCode}`
              } else {
                booking_url = `https://booking.tigerairtw.com/?type=oneWay&outbound=${departureStation}-${arrivalStation}&departureDate=${newDepartureDate}&adult=${adult}&children=${children}&infant=${infant}&languageCode=zh-tw&affiliateChannel=${affiliateChannel}&affiliateCode=${trackCode}-${departureStation}${arrivalStation}-${departureDate}&promotionCode=${promoCode}`
              }
            } else {
              booking_url = `https://booking.tigerairtw.com/?type=roundTrip&outbound=${departureStation}-${arrivalStation}&departureDate=&returnDate=&adult=${adult}&children=${children}&infant=${infant}&languageCode=zh-tw&affiliateChannel=${affiliateChannel}&affiliateCode=${trackCode}-${departureStation}${arrivalStation}&promotionCode=${promoCode}`
            }

            break
          }
          case "TR": {
            if (departureDate !== null) {
              const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")
              if (arrivalDate !== null) {
                const newArrivalDate = moment(arrivalDate).format("YYYY-MM-DD")
                booking_url = `https://booking.flyscoot.com/Book/Flight?culture=zh-TW&isBeta&type=return&dst1=${departureStation}&ast1=${arrivalStation}&dst2=${arrivalStation}&ast2=${departureStation}&dd=${newDepartureDate}&rd=${newArrivalDate}&adt=${adult}&chd=${children}&inf=${infant}`
              } else {
                booking_url = `https://booking.flyscoot.com/Book/Flight?culture=zh-TW&isBeta&type=oneway&dst1=${departureStation}&ast1=${arrivalStation}&dst2=${arrivalStation}&ast2=${departureStation}&dd=${newDepartureDate}&rd=null&adt=${adult}&chd=${children}&inf=${infant}`
              }
            } else {
              booking_url = `https://booking.flyscoot.com/?culture=zh-TW`
            }

            break
          }

          case "MM": {
            let s = []
            if (departureDate !== null) {
              const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")
              if (arrivalDate !== null) {
                const newArrivalDate = moment(arrivalDate).format("YYYY-MM-DD")
                s = [
                  {
                    departure_date: newDepartureDate,
                    departure_airport_code: departureStation,
                    arrival_airport_code: arrivalStation,
                    is_return: true,
                    return_date: newArrivalDate,
                    adult_count: adult,
                    child_count: children,
                    infant_count: infant,
                  },
                ]
              } else {
                s = [
                  {
                    departure_date: newDepartureDate,
                    departure_airport_code: departureStation,
                    arrival_airport_code: arrivalStation,
                    is_return: false,
                    return_date: "",
                    adult_count: adult,
                    child_count: children,
                    infant_count: infant,
                  },
                ]
              }
            } else {
              const newDepartureDate = moment().add(2, "d").format("YYYY-MM-DD")
              s = [
                {
                  departure_date: newDepartureDate,
                  departure_airport_code: departureStation,
                  arrival_airport_code: arrivalStation,
                  is_return: false,
                  return_date: "",
                  adult_count: adult,
                  child_count: children,
                  infant_count: infant,
                },
              ]
            }
            const query_str = encodeURIComponent(JSON.stringify(s))
            booking_url = `https://booking.flypeach.com/tw/getsearch?s=${query_str}`
            break
          }

          // https://www.vietjetair.com/?departAirport=TPE&arrivalAirport=PQC&languageCode=zh-tw&departDate=2024-06-10&returnDate=2024-06-10&tripType=roundTrip&adultCount=1&refCode=9015&companyKey=IEJityRw6703cYsRZzXypmXbrNIXce9eMehDƒBXrPao=&itm_source=Leadr&itm_medium=Landing_Page

          // https://www.vietjetair.com/?departAirport=SGN&arrivalAirport=RMQ&languageCode=zh-tw&departDate=2024-06-25&tripType=oneway&adultCount=1&refCode=9015&companyKey=IEJityRw6703cYsRZzXypmXbrNIXce9eMehD%C6%92BXrPao=&itm_source=Leadr&itm_medium=Landing_Page
          case "VZ": {
            const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")
            if (arrivalDate !== null) {
              const newArrivalDate = moment(arrivalDate).format("YYYY-MM-DD")
              booking_url = `https://www.vietjetair.com/?departAirport=${departureStation}&arrivalAirport=${arrivalStation}&languageCode=zh-tw&departDate=${newDepartureDate}&returnDate=${newArrivalDate}&tripType=roundTrip&adultCount=${adult}&childCount=${children}&infantCount=${infant}`
            } else {
              booking_url = `https://www.vietjetair.com/?departAirport=${departureStation}&arrivalAirport=${arrivalStation}&languageCode=zh-tw&departDate=${newDepartureDate}&tripType=oneway&adultCount=${adult}&childCount=${children}&infantCount=${infant}`
            }
            break
          }
          case "VJ": {
            const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")
            if (arrivalDate !== null) {
              const newArrivalDate = moment(arrivalDate).format("YYYY-MM-DD")
              booking_url = `https://www.vietjetair.com/?departAirport=${departureStation}&arrivalAirport=${arrivalStation}&languageCode=zh-tw&departDate=${newDepartureDate}&returnDate=${newArrivalDate}&tripType=roundTrip&adultCount=${adult}&childCount=${children}&infantCount=${infant}`
            } else {
              booking_url = `https://www.vietjetair.com/?departAirport=${departureStation}&arrivalAirport=${arrivalStation}&languageCode=zh-tw&departDate=${newDepartureDate}&tripType=oneway&adultCount=${adult}&childCount=${children}&infantCount=${infant}`
            }
            break
          }
          case "FD": {
            const newDepartureDate = moment(departureDate).format("DD/MM/YYYY")
            if (arrivalDate !== null) {
              const newArrivalDate = moment(arrivalDate).format("DD/MM/YYYY")
              booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&returnDate=${newArrivalDate}&tripType=R&adult=${adult}&child=${children}&infant=${infant}&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=&isAirasiaFlightOnly=true`
            } else {
              booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&tripType=O&adult=${adult}&child=${children}&infant=${infant}&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=&isAirasiaFlightOnly=true`
            }

            break
          }
          case "AK": {
            const newDepartureDate = moment(departureDate).format("DD/MM/YYYY")
            if (arrivalDate !== null) {
              const newArrivalDate = moment(arrivalDate).format("DD/MM/YYYY")
              booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&returnDate=${newArrivalDate}&tripType=R&adult=${adult}&child=${children}&infant=${infant}&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=&isAirasiaFlightOnly=true`
            } else {
              booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&tripType=O&adult=${adult}&child=${children}&infant=${infant}&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=&isAirasiaFlightOnly=true`
            }

            break
          }
          case "D7": {
            const newDepartureDate = moment(departureDate).format("DD/MM/YYYY")
            if (arrivalDate !== null) {
              const newArrivalDate = moment(arrivalDate).format("DD/MM/YYYY")
              booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&returnDate=${newArrivalDate}&tripType=R&adult=${adult}&child=${children}&infant=${infant}&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=&isAirasiaFlightOnly=true`
            } else {
              booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&tripType=O&adult=${adult}&child=${children}&infant=${infant}&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=&isAirasiaFlightOnly=true`
            }

            break
          }
          case "BX": {
            const newDepartureDate = moment(departureDate).format("YYYY-MM-DD")
            let travel_type = "OW",
              newArrivalDate = moment(departureDate).format("YYYY-MM-DD")
            if (arrivalDate !== null) {
              newArrivalDate = moment(arrivalDate).format("YYYY-MM-DD")
              travel_type = "RT"
            }

            booking_url = `https://tw.airbusan.com/web/individual/booking/flightsAvail/${travel_type}/${departureStation}/${arrivalStation}/${newDepartureDate}/${arrivalStation}/${departureStation}/${newArrivalDate}/0/${adult}/${children}/${infant}/N`
            break
          }

          // https://search.malindoair.com/search.aspx?aid=91&Jtype=2&depCity=KHH&arrCity=KUL&depDate=09%2F06%2F2024&arrDate=10%2F06%2F2024&adult1=1&child1=0&infant1=0&promotioncode=&culture=zh-CN&df=UK&afid=0&b2b=0&St=fa&DFlight=false&roomcount=1&sid=

          // https://search.malindoair.com/search.aspx?aid=91&Jtype=1&depCity=KHH&arrCity=KUL&depDate=09%2F06%2F2024&adult1=1&child1=0&infant1=0&promotioncode=&culture=zh-CN&df=UK&afid=0&b2b=0&St=fa&DFlight=false&roomcount=1&sid=

          case "OD": {
            const random_number = 91
            const newDepartureDate = encodeURIComponent(moment(departureDate).format("DD/MM/YYYY"))
            if (arrivalDate !== null) {
              const newArrivalDate = encodeURIComponent(moment(arrivalDate).format("DD/MM/YYYY"))
              booking_url = `https://search.malindoair.com/search.aspx?aid=${random_number}&Jtype=2&depCity=${departureStation}&arrCity=${arrivalStation}&depDate=${newDepartureDate}&arrDate=${newArrivalDate}&adult1=${adult}&child1=${children}&infant1=${infant}&promotioncode=&culture=zh-CN&df=UK&afid=0&b2b=0&St=fa&DFlight=false&roomcount=1&sid=`
            } else {
              booking_url = `https://search.malindoair.com/search.aspx?aid=${random_number}&Jtype=1&depCity=${departureStation}&arrCity=${arrivalStation}&depDate=${newDepartureDate}&adult1=${adult}&child1=${children}&infant1=${infant}&promotioncode=&culture=zh-CN&df=UK&afid=0&b2b=0&St=fa&DFlight=false&roomcount=1&sid=`
            }

            break
          }

          default:
            break
        }
        if (show_type === "html") {
          return `<a href="${booking_url}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i> 前往官網</a>`
        }
        if (show_type === "text") {
          return booking_url
        }
      },
    },
  }
</script>
<style></style>
